var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F rB",
      "1028": "B",
      "1316": "A"
    },
    B: {
      "1": "C K L G M N O P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "164": "sB gB I i J D E F A B C K L G M N O j k l tB uB",
      "516": "m n o p q r"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "33": "l m n o p q r s",
      "164": "I i J D E F A B C K L G M N O j k"
    },
    E: {
      "1": "F A B C K L G 1B mB eB fB 2B 3B 4B nB oB 5B",
      "33": "D E zB 0B",
      "164": "I i J xB lB yB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S fB",
      "2": "F B C 6B 7B 8B 9B eB pB AC",
      "33": "G M"
    },
    G: {
      "1": "GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "33": "E EC FC",
      "164": "lB BC qB CC DC"
    },
    H: {
      "1": "VC"
    },
    I: {
      "1": "H aC bC",
      "164": "gB I WC XC YC ZC qB"
    },
    J: {
      "1": "A",
      "164": "D"
    },
    K: {
      "1": "U fB",
      "2": "A B C eB pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "1": "B",
      "292": "A"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "1": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 4,
  C: "CSS Flexible Box Layout Module"
};
export default exports;